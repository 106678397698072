<template>
    <v-navigation-drawer
        :mini-variant.sync="mini"
        permanent app clipped
    >
        <v-list-item class="px-2">
            <v-list-item-avatar>
                <!--https://randomuser.me/api/portraits/men/85.jpg-->
                <v-img :src="$auth.user().avatar_url"></v-img>
            </v-list-item-avatar>

            <v-list-item-title>
                {{$auth.user().name}}
                <v-btn  x-small  icon :to="{name: 'admin.profile'}">
                    <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
            </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
            <v-list-item-group v-model="selectedItem">
                <v-list-item
                    v-for="(link, index) in menu"
                    :key="index"
                    @click.prevent="handleMenuClick">
                    <v-list-item-icon>
                        <v-icon v-text="link.icon"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="link.text"/>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import menu from "./menu";

    export default {
        name: "AdminDrawerMenu",
        props: ['mini'],
        data () {
            return {
                menu: menu,
                selectedItem: menu.findIndex(m => this.$route.name === m.to.name)
            }
        },
        methods: {
            handleMenuClick() {
                this.$nextTick(() => this.$router.push({ name: this.menu[this.selectedItem].to.name }))
            }
        },
    }
</script>

<style scoped>

</style>
